import { _ as _applyDecoratedDescriptor, a as _initializerDefineProperty } from '../_rollupPluginBabelHelpers-7db585ca.js';
import Helper from '@ember/component/helper';
import { inject } from '@ember/service';

var _class, _descriptor;
let RootURLHelper = (_class = class RootURLHelper extends Helper {
  constructor(...args) {
    super(...args);

    _initializerDefineProperty(this, "router", _descriptor, this);
  }

  compute() {
    return this.router.rootURL;
  }

}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [inject], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);

export { RootURLHelper as default };
