import { _ as _applyDecoratedDescriptor, a as _initializerDefineProperty } from '../_rollupPluginBabelHelpers-7db585ca.js';
import { inject } from '@ember/service';
import Helper from '@ember/component/helper';
import RouteParams from '../utils/route-params.js';
import '../utils/handle-query-params.js';
import '../utils/track-active-route.js';

var _class, _descriptor;
let RouteParamsHelper = (_class = class RouteParamsHelper extends Helper {
  constructor(...args) {
    super(...args);

    _initializerDefineProperty(this, "router", _descriptor, this);
  }

  compute(params) {
    return new RouteParams(this.router, params);
  }

}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [inject], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);

export { RouteParamsHelper as default };
